import type { NextPage } from "next";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useAppContext } from "application/context/state";
import { theme } from "ui";
import { buildTestIds } from "application";
import { Link } from "../..";
import {
  Error404Container,
  Error404Wrapper,
  GoBackIcon,
  GoHomeIcon,
  GoSearchIcon,
  GoSearchText,
  ImageSadFaceContainer,
} from "./styles";

const Error404: NextPage = () => {
  const router = useRouter();
  const { handleFocusInputSearch } = useAppContext();

  useEffect(() => {
    document.title = "Ops! :(";
  }, []);

  return (
    <Error404Container {...buildTestIds("error-404-container")}>
      <ImageSadFaceContainer {...buildTestIds("image-sadface-wrapper")}>
        <Error404Wrapper {...buildTestIds("error-404-wrapper")}>
          <header {...buildTestIds("header-error-404")}>
            <h1>Ops!</h1>
            <h3>Não encontramos a página que você procura.</h3>
          </header>
          <footer {...buildTestIds("footer-error-404")}>
            <div>
              <h4>O que pode ter acontecido?</h4>
              <span>- O endereço da página foi digitado incorretamente</span>
              <span>- A página pode estar indisponível</span>
            </div>
            <div>
              <h4>O que você pode fazer?</h4>
              <Link
                target="_self"
                href="/"
                onClick={() => router.back()}
                {...buildTestIds("link-router-back")}
                color={theme.colors.primary["200"]}
              >
                <GoBackIcon />
                <GoSearchText>Voltar para a página anterior</GoSearchText>
              </Link>
              <Link
                target="_self"
                href="/"
                color={theme.colors.primary["200"]}
                {...buildTestIds("link-page-home")}
              >
                <GoHomeIcon />
                <GoSearchText>Ir para a página inicial</GoSearchText>
              </Link>
              <Link
                target="_self"
                href="/"
                {...buildTestIds("link-focus-input-search")}
                color={theme.colors.primary["200"]}
                onClick={() => handleFocusInputSearch()}
              >
                <GoSearchIcon />
                <GoSearchText>Fazer uma nova busca</GoSearchText>
              </Link>
            </div>
          </footer>
        </Error404Wrapper>
      </ImageSadFaceContainer>
    </Error404Container>
  );
};

export default Error404;
